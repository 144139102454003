.customer-detail-container {
  width: 100%;
  display: flex;
  border-top: 2px solid #FFFFFF;
  font-family: 'Poppins', sans-serif;
  height: 1200px;
}

.cust-Detail-inside p {
  display: flex;
  /* Flexbox for better alignment of icon and text */
  align-items: center;
  /* Vertically align icon and text */
}

.BOOKicon {
  color: #FFBE00;
  /* Icon color */
  margin-right: 10px;
  /* Space between icon and text */
}

/* right side bar Start  */
.rightsidebar {
  width: 70%;
  height: 100%;
  padding: 40px 20px 20px 20px;
  border-left: 2px solid #FFFFFF;
  /* background-color: #b72a2a; */
}

.rightsidebar h1 {
  font-size: 48px;
  background: linear-gradient(90deg, #ff6b00 0%, #ffbe00 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
  margin-bottom: 5px;
}

/* main content start */
.customer-form-container {
  width: 970px;
  height: 585px;
  /* background: blueviolet; */
  display: flex;
  justify-content: space-between;
}

.rightsidebar .successfullDiv {
  margin-top: 30px;
  width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* background-color: #b61d1d; */
}

.rightsidebar .successfullDiv p {
  margin: 5px;
  /* border: 3px solid yellowgreen; */
}

.rightsidebar .successfullDiv .top {
  width: 323px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -100px;
  margin-left: -70px;
}

.rightsidebar .successfullDiv .top img {
  width: 80px;
  height: 80px;
}

.rightsidebar .successfullDiv .top .title {
  text-align: center;
  background: linear-gradient(336.84deg, #ff6b00 -74.16%, #ffbe00 85.34%);
  font-weight: 600;
  font-size: 24px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Poppins', sans-serif;
  line-height: 36px;
  width: 100%;
}

.servicesId {
  white-space: nowrap;
  width: 100%;
  margin: 0;
}

.rightsidebar .successfullDiv .top .servicesId {
  font-size: 24px;
  font-weight: 500;
  font-style: italic;
  line-height: 36px;
  /* align-items: center; */
  background: linear-gradient(90.77deg, #ff6b00 19.61%, #ffbe00 91.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custo-Detail-Address {
  display: flex;
  /* justify-content: space-between; */
  /* height: 248px; */
  width: 500px;
  gap: 48px;
  /* border: 1px solid yellowgreen; */
}

.custo-Detail-Address .cust-Detail {
  width: 172px;
  height: 229px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  /* border: 1px solid yellow; */
}

/* left section start  */
.custo-Detail-Address .cust-Detail .cust-Detail-heading-left {
  color: #ffbe00;
  text-decoration: underline;
  height: 20px;
  font-weight: 600;
}

.custo-Detail-Address .cust-Detail .cust-Detail-inside {
  gap: 9px;
  font-style: italic;
  font-size: 16px;
  line-height: 24px;
}

.custo-Detail-Address .cust-Detail .cust-Detail-inside p {
  height: 24px;
  /* border: 1px solid yellowgreen; */
}

/* left section end  */
/* right section start  */
.custo-Detail-Address .cust-Address .cust-Detail-heading-right {
  color: #ffbe00;
  text-decoration: underline;
  height: 20px;
  font-weight: 600;
}

.custo-Detail-Address .cust-Address {
  width: 172px;
  height: 248px;
  /* border: 1px solid yellow; */
  gap: 16px;
}

.custo-Detail-Address .cust-Address .cust-Detail-child-2 {
  height: 80px;
  /* background-color: greenyellow; */
  gap: 8px;
}

.custo-Detail-Address .cust-Address .cust-Detail-child-3 {
  height: 80px;
  /* background-color: greenyellow; */
  gap: 8px;
}

.custo-Detail-Address .cust-Address .cust-Detail-child-2 .cust-Detail-p2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffbe00;
}

.custo-Detail-Address .cust-Address .cust-Detail-child-2 .cust-Detail-p3 {
  font-size: 16px;
  font-style: italic;
  font-weight: 500;
  width: 200px;
  line-height: 24px;
}

.custo-Detail-Address .cust-Detail-p4 {
  color: #ffbe00;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.custo-Detail-Address .cust-Detail-p5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-style: italic;
  width: 200px;

}

.custo-Detail-Address .cust-Detail-p6 {
  font-size: 18px;
  height: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #ffbe00;
  text-decoration: underline;
  white-space: nowrap;
}

/* right section end  */

/*successfullDetailsDiv start ---------- */
.customer-form-container .successfullDetailsDiv {
height: 800px;
  align-items: center;
  justify-content: center;
  border: 2px solid #ffbe00;
  border-radius: 16px;
  padding: 10px 16px;
  margin-top: 150px;
  width: 396px;
  margin-left: -50px;
}

.booklogo img {
  width: 60.65px;
  height: 60px;
}

.booklogo {
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}

.booklogo p {
  font-size: 23.23px;
  width: auto;
}

.subtotalbook p {
  color: #FFBE00;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
}

#bookdownload {
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: underline;
  color: #ffbe00;
  cursor: pointer;
  justify-content: center;
}

.customer-form-container {
  align-items: center;
  justify-content: center;
}

.customer-form-container .successfullDetailsDiv .booking-summary-right-ul-lis li {
  display: flex;
  margin: 5px;
  font-size: 16px;
}

#bookli {
  align-items: center;
  gap: 8px;
}

.successfullDetailsDiv p {
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  white-space: nowrap;

}

.successfullDetailsDiv span {
  font-size: 20px;
  color: #FFBE00;
}

#invoiceyeelo {
  color: #ffbe00;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

#selectedbook {
  color: #FFBE00;
  line-height: 27px;
  font-weight: 600;
  font-size: 18px;
}

.booking-summary-right-sec p {
  display: flex;
}

.booking-summary-right-sec ol li {

  display: flex;
  justify-content: space-between;
}

.booking-summary-right-sec div {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #FFBE00;
  border-radius: 3px;
}

.rightsidebar .custo-fotterdiv {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  gap: 28px;
}

.custo-support-p1 {
  text-decoration: underline;
  font-size: 18px;
  height: 25px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #ffbe00;
  white-space: nowrap;


}

.custo-support-p2 {
  color: #ffffff;
  padding: 8px 16px;
  border: 2px solid transparent;
  border-radius: 25px;
  background: linear-gradient(to right, #000, #000),
    linear-gradient(to right, #ffbe00, #ff6b00);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

/* footer div end */
/* right side bar end  */

@media (min-width: 1200px) and (max-width: 1400px) {
  .customer-detail-container {
    width: 100%;
  }
  .rightsidebar{
    width: 60%;
  }
  .rightsidebar h1{
font-size: 30px;
  }
  .customer-form-container{
    width: auto;
  }
  .custo-Detail-Address{
    gap:'10px'
  }
  .customer-form-container .successfullDetailsDiv {
width: auto;
}
.successfullDetailsDiv p span {
  font-size: 18px;
}
.rightsidebar .successfullDiv .top .title {
  font-size: 18px;
}
.custo-Detail-Address{
  width: 400px;
  gap:10px
}
}