 .SidebarServices {
    width: 220px;
    padding: 5px 40px;
    font-family: "Poppins", sans-serif;}
    .service-count {
        margin-left: 10px; 
        padding: 5px 10px; 
        border: 2px solid #fff; 
        border-radius: 50%; 
        display: inline-block;
        font-size: 14px;
      }
      
.service-menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.service-item-web {
    margin: 10px 0;
    font-size: 16px;
}

.service-item-web a {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    background: linear-gradient(170deg,   #bb780b 0%, #6F3800 60%, #bb780b 100%,#9c876a 0%); 
}

.service-item-web.active a {
    border: 1px solid #FFBE00;
}

.active-icon, .inactive-icon {
    color: #fff;
    font-size: 14px;
}

@media screen and (max-width:768px) {
    .SidebarServices {
    display: none;
}
} 

