.Our-container {
  position: relative;
  width: 100%;
  height: 800px;
  background-image: url('../image/serviceimage/image\ 92.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.h1text {
  text-align: center;
  width: 100%;
  height: 75px;
  top: 58px;
  left: 473px;
  gap: 0px;
  opacity: 0px;
}

.h1text h1 {
  font-size: 64px;
  font-weight: 600;
  line-height: 74.81px;
  background: linear-gradient(90deg, #ff6b00 0%, #ffbe00 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  text-align: center;
  margin: 10px;padding: 30px;
}

.content {
  position: relative;
  padding: 10px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 100px;
}
.card-title {
  font-size: 22px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  padding: 10px;
  margin: 0;
  font-family: 'Poppins', sans-serif;

}
.card-body p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.92px;
  text-align: left;
  color: #ffffff81;
  padding-left: 10px;
  width: 75%;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

/* icons start  */
.icon {
  width: 64px;
  height: 64px;
  position: absolute;
  top: 50%;
  left: 31%;
  transform: translate(-50%, -50%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon .cardlogo {
  width: 64px;
  height: 64px;
}

/* icons end  */

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 85%;
}

.maincard1 {
  height: 152px;
  border: 1px solid rgb(234, 120, 6);
  border-radius: 20px;
  background-image: url('../image/serviceimage/image\ \(1\).png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 40% auto;
  background-position: left center;
  position: relative;
  width: 320px;
  box-shadow: 
  0 2px 10px rgba(218, 212, 140, 0.2), 
  2px 2px 10px rgba(216, 205, 160, 0.5); 
}

.maincard2 {
  width: 320px;
  height: 152px;
  border: 1px solid rgb(234, 120, 6);
  border-radius: 20px;
  background-image: url('../image/serviceimage/image\ \(2\).png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 40% auto;
  background-position: left center;
  position: relative;
  box-shadow: 
  0 2px 10px rgba(218, 212, 140, 0.2), 
  2px 2px 10px rgba(216, 205, 160, 0.5); 
}
.maincard3 {
  height: 152px;
  width: 320px;
  border: 1px solid rgb(234, 120, 6);
  border-radius: 20px;
  background-image: url('../image/serviceimage/image\ \(3\).png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 40% auto;
  background-position: left center;
  position: relative;
  box-shadow: 
  0 2px 10px rgba(218, 212, 140, 0.2), 
  2px 2px 10px rgba(216, 205, 160, 0.5); 
}
.maincard4 {
  width: 320px;
  height: 152px;
  border: 1px solid rgb(234, 120, 6);
  border-radius: 20px;
  background-image: url('../image/serviceimage/image\ \(4\).png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 40% auto;
  background-position: left center;
  position: relative;
  box-shadow: 
  0 2px 10px rgba(218, 212, 140, 0.2), 
  2px 2px 10px rgba(216, 205, 160, 0.5); 
}
.maincard5 {
  width: 320px;
  height: 152px;
  border: 1px solid rgb(234, 120, 6);
  border-radius: 20px;
  background-image: url('../image/serviceimage/image\ \(5\).png');
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 40% auto;
  background-position: left center;
  position: relative;
  box-shadow: 
  0 2px 10px rgba(218, 212, 140, 0.2), 
  2px 2px 10px rgba(216, 205, 160, 0.5); 
}
.maincard6 {
  width: 320px;
  height: 152px;
  border: 1px solid rgb(234, 120, 6);
  border-radius: 20px;
  background-image: url('../image/serviceimage/image\ \(6\).png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 40% auto;
  background-position: left center;
  position: relative;
  box-shadow: 
  0 2px 10px rgba(218, 212, 140, 0.2), 
  2px 2px 10px rgba(216, 205, 160, 0.5); 
}
.maincard7 {
  height: 152px;
  width: 320px;
  border: 1px solid rgb(234, 120, 6);
  border-radius: 20px;
  background-image: url('../image/serviceimage/image\ \(7\).png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 40% auto;
  background-position: left center;
  position: relative;
  box-shadow: 
  0 2px 10px rgba(218, 212, 140, 0.2), 
  2px 2px 10px rgba(216, 205, 160, 0.5); 
}
.maincard8 {
  width: 320px;
  height: 152px;
  border: 1px solid rgb(234, 120, 6);
  border-radius: 20px;
  background-image: url('../image/serviceimage/image\ \(8\).png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 40% auto;
  background-position: left center;
  position: relative;
  box-shadow: 
  0 2px 10px rgba(218, 212, 140, 0.2), 
  2px 2px 10px rgba(216, 205, 160, 0.5); 
}
.maincard1:hover,
.maincard2:hover,
.maincard3:hover,
.maincard4:hover,
.maincard5:hover,
.maincard6:hover,
.maincard7:hover,
.maincard8:hover{
  border: 1px solid #e6ae07;
}
.dropdowncircle {
  transition: all linear 1s;
}
.card {
  height: 152px;
  width: 320px;
  display: flex;
  justify-content: end;
  box-sizing: border-box;
}
.card-body-bg {
  width: 220px;
  height: 100%;
  background: linear-gradient(137.15deg, #ff6b00 13.93%, #ffc107 48.11%);
  border-radius: 20px;
}
.card-body {
  width: 220px;
  height: 152px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  border-radius: 20px;
  gap: 10px;
  margin: 0;
  background: linear-gradient(137.15deg, #ff6b00 13.93%, #ffc107 48.11%);
  background-image: url(../image/Rectangle\ 380.png); /* Ensure the path is correct */
  background-size: cover;
  background-position: center; /* Adjust as needed */
  background-repeat: no-repeat;
}
a {
  text-decoration: none; 
  color: inherit; 
}
@media screen and (max-width: 1024px){
  .row {
    width: 100%;
    height: auto;
  }
  .content{
    padding-top: 50px;
  }
}
@media screen and (max-width: 480px) {
  .row {
    display: flex;
    flex-direction: column;
  }
  .Our-container {
    margin: 0;
    padding: 0;
  }
  .content {
    padding: 0px;
    margin: 0;
  }
  .h1text h1 {
    font-size: 44px;
  }
  .card-title {
    font-size:14px;
  }
}
