.details-form{
  font-family: 'Chakra Petch', sans-serif; 
}
.payment-container{
  display: flex;
  gap: 30px;
}
.banking{  
    width: 527px;
  height: 584px;
  border: 1px solid transparent ;
  border-radius: 16px;
  background-color: #000;
}
.heading-with-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure the flex container takes up full width */
}

.Netbanking, .debit, .UPI {
  cursor: pointer; /* Optional: cursor pointer to indicate clickable area */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid transparent ;
  border-radius: 20px;
  background: linear-gradient(to right, #161616, #161616), 
  linear-gradient(to right,#FF6B00, #FFBE00 ); 
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #eaeaea; 
  box-shadow: 6px 6px 12px rgba(255, 190, 0, 0.25);
}
.heading-with-icon h3 {
  margin: 0; 
   font-size: 20px;
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-left: 10px;
}
.bank-label label{
  display: flex;
  align-items: center;
  cursor: pointer;
  color:#fff;
  font-family: 'Poppins', sans-serif;
padding-left: 15px;
}
.go-back{
  display: flex;
  float: right;
}
.go-back button{
  padding: 10px 20px;
  font-size: 14px;
  background: transparent;
  color: #fff;
  border-radius: 20px;
  margin: 5px;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  width: 200px;
  height: 52px;
  border: 2px solid transparent;
  background: linear-gradient(to right, #000000, #000000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.pay-bank button{
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
  border: none;
}
.bank-dropdown-content input{
  padding: 10px 20px;
  font-size: 14px;
  background: transparent;
  color: #fff;
  border-radius: 30px;
  margin: 5px;
  white-space: nowrap;
  transition: background-color 0.3s ease;
  width: 300px;
  height: 35px;
  border: 2px solid transparent;
  background: linear-gradient(to right, #161616, #161616), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.bank-dropdown-content input:focus {
  outline: none; /* Removes the default blue outline */
  box-shadow: 0 0 10px rgba(255, 190, 0, 0.8);
  border: 2px solid #FFBE00;
}
.bank-dropdown-content input::placeholder{
  color: #ffffffd1;
  font-family: 'Poppins', sans-serif;

}
.heading-with-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* Ensure the flex container takes up full width */
  padding: 10px 0; /* Add padding for spacing */
}
.Netbanking:hover, .debit:hover, .UPI:hover {
background-color: #000;
}


.heading-with-icon svg {
  font-size: 24px; /* Icon size */
  color: #FFBE00; /* Icon color */
  transition: transform 0.3s; /* Smooth transition for transform effects */
}

/* Rotate icon when dropdown is active */
.Netbanking .heading-with-icon svg.active,
.debit .heading-with-icon svg.active,
.UPI .heading-with-icon svg.active {
  transform: rotate(180deg); 
}

.payment-box{
    width: 400px;
    height: 500px;
    border: 2px solid transparent ;
    border-radius: 20px;
    background: linear-gradient(to right, #161616, #161616), 
    linear-gradient(to right,#FFBE00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
}
.payment-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .payment-logo a {
    font-size: 1.3rem;
    color: #fff; 
    text-decoration: none;
    font-weight: bolder;
  }
  
  .payment-logo img {
    width: 80px;
  }
  .payment-logo img:hover {
    animation: spin 1s linear forwards; 
    transition: transform var(--ifm-transition-fast) var(--ifm-transition-timing-default);
  }
  .partner-garage h3{
    font-size: 18px;
    background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 10px;
  }