.car-info-container {
  display: flex;
  position: relative;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  height: 700px; 
  width: 100%; 
  margin-left: 5px;
  justify-content: left;
  margin-bottom: 20px;
  margin-top: 50px;
  overflow: hidden;
  flex-direction: column;
}
.car-info-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../image/Carage.png');
  background-size: cover;
  background-position: center;
  filter: brightness(40%);
  z-index: -1; 
  border-radius: 10px; 
}
.Car-age {
  display: flex;
  flex-direction: column;
}
.white-text {
  background: #fff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Car-channel-partner {
  width: 45%;
  color: #fff;
  margin: 50px;
}

.channel-heading {
  font-size: 40px;
  text-align: center;
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: wrap;
  margin-left: 50px;
  margin-top: 150px;

}

.findpartner button {
  align-items: center;
  font-size: 20px;
  color: #fff;
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
  width: 150px;
  padding: 10px;
  border: none;
  border-radius: 30px;
  margin-left: 300px;
  cursor: pointer;
}
.car-info-content {
  margin-right: 0px;
  flex: 1 1 100%;
  text-align: center;
  padding: 30px;
}
.car-info-content h2 {
  font-size: 50px;
  background: linear-gradient(90deg,#FF6B00 0%,  #FFBE00  100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  font-family: '"Chakra Petch", sans-serif';
  white-space: nowrap;
  padding-bottom: 50px;
}
.car-info-content p {
  font-size: 23px;
  color: #fff;
  text-align: left;
  margin: 0;
}
.TreatmentCategory {
  flex-direction: column;
  white-space: nowrap;
  background: linear-gradient(40deg,   #bb780b 0%, #6F3800 60%, #bb780b 100%,#9c876a 0%); 
  border-radius: 20px; 
  width: 430px;
  height: 320px;
  padding: 30px;
}
.TreatmentCategory button:hover {
  border-radius: 12px;
  box-sizing: border-box;
  border: 3px solid transparent;
  background: linear-gradient(to right, #6f370053, #bb770bb0 ), 
              linear-gradient(to right,#FF6B00, #FFBE00 ); 
  background-origin: border-box;
  background-clip: padding-box, border-box;
  
}
.TreatmentCategory button:focus {
  border-radius: 12px;
  box-sizing: border-box;
  border: 3px solid transparent;
  background: linear-gradient(to right, #f3a058, #7f642bd5 ), 
              linear-gradient(to right,#FF6B00, #FFBE00 ); 
  background-origin: border-box;
  background-clip: padding-box, border-box;
  font-weight: bold;
}
.TreatmentCategory .oNe {
  display: flex;
  justify-content: space-between;
}
.treatmentpartone, .treatmentparttwo {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.TreatmentCategory button {
  margin: 5px;
  padding: 10px 10px;
  border-radius: 12px;
  background: linear-gradient(170deg,   #bb780b 0%, #6F3800 60%, #bb780b 100%,#9c876a 0%); 
  cursor: pointer;
  color: whitesmoke;
  border: none;
  width: 200px;
  height: 50px;
}
.input-group {
  display: flex;
  align-items: center;
  background: linear-gradient(170deg, 
              rgba(187, 120, 11, 0.6) 0%,  
              rgba(111, 56, 0, 0.6) 60%, 
              rgba(187, 120, 11, 0.6) 100%,  
              rgba(156, 135, 106, 0.6) 0%  
            ); 
  border-radius: 48px;
  font-size: 12px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #C27304;
  width: 689px;
  height: 64px;
  margin-top: 5px;
  margin-left: 10px;
}
.input-group input::placeholder {
  color: white;
  opacity: 10;
}
.input-group input {
  border: none;
  background: transparent;
  padding: 10px;
  border-radius: 30px;
  outline: none;
  flex: 1;
  background: transparent;
  font-size: 13px;
  color: #fff;
}
.input-group button {
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
  border: none;
  color: white;
  border-radius: 48px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  width: 126px;
  height: 64px;
  margin-left: 5px;
}
.input-group button:hover{
background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%);
}
.car-age-section h2 {
  color: white;
  font-family: 'Chakra Petch', sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
}
.car-age-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  outline: none;
  margin-bottom: 20px;
  background-color: #444;
  color: white;
}
.age-submit-button {
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 16px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}
.whatsappicon img {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 55px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
}
@media (max-width: 1024px){
.TreatmentCategory {
  flex-direction: column;
  white-space: nowrap;
  background: linear-gradient(40deg,   #bb780b 0%, #6F3800 60%, #bb780b 100%,#9c876a 0%); 
  border-radius: 20px; 
  width: 350px;
  height: 230px;
  padding: 10px;
}
.TreatmentCategory button {
  margin: 5px;
  padding: 0px 0px;
  border-radius: 5px;
  background: linear-gradient(170deg,   #bb780b 0%, #6F3800 60%, #bb780b 100%,#9c876a 0%); 
  cursor: pointer;
  color: whitesmoke;
  border: none;
  width: 150px;
  height: 30px;
  font-size: 12px;
}
.Car-channel-partner {
  background-color: transparent;
  margin: 10px;
}
.car-info-content h2 {
  font-size: 30px;
}
}
@media (min-width: 1200px) {
  .car-info-content {
    max-width: 100%;
    text-align: center;
  }

  .car-info-image img {
    height: auto;
  }
  .input-group{
    width: 569px;
  }
}
@media (min-width: 768px) {
  .Car-age {
    flex-direction: row; /* Row direction for tablets and larger screens */
  }

  .car-info-container {
    width: 70%; /* Reduce width on larger screens */
  }

  .Car-channel-partner {
    width: 50%; /* Adjust width for larger screens */
    margin: 50px; /* Keep margins for larger screens */
  }
}

@media (max-width: 768px) {
  .car-info-content h2 {
    font-size: 28px; /* Smaller font size for headings */
  }

  .car-info-content p {
    font-size: 18px; /* Smaller font size for paragraphs */
  }

  .TreatmentCategory {
    width: 90%; /* Wider buttons for mobile */
    height: auto; /* Adjust height for mobile */
    padding: 20px; /* Reduce padding */
  }

  .TreatmentCategory button {
    width: 100%; /* Make buttons full width */
    height: 40px; /* Adjust height for buttons */
  }

  .input-group {
    width: 90%; /* Wider input field for mobile */
    height: 45px; /* Adjust height */
    margin-left: 0; /* Center input field */
  }

  .input-group input {
    font-size: 14px; /* Adjust font size for input */
  }

  .input-group button {
    width: 30%; /* Adjust button width */
    height: 45px; /* Adjust height */
  }

  .whatsappicon img {
    width: 35px; /* Smaller icon size */
    height: 35px; /* Smaller icon size */
  }
}

@media (max-width: 480px) {
  .car-info-container{
    height: auto;
  }
  .car-info-content h2 {
    font-size: 24px; /* Further reduce heading size */
  }

  .car-info-content p {
    font-size: 16px; /* Further reduce paragraph size */
  }

  .TreatmentCategory {
    padding: 10px; /* Reduce padding */
    min-width: 290px;
    margin: 0 auto;
  }

  .input-group {
    height: 40px; /* Further adjust input height */
  }
}