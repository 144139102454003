body{
    background-color: #000;
    margin: 0;
    padding: 0;
    }
    .washingpage-container{
        border-top:2px solid #FFFFFF ;
    }
    .main-contant{
        width: 99%;
        height: auto;
        background-color: #000;
        border-left: 2px solid #FFFFFF;
        padding-left: 15px;
    }
    .wash-head {
        text-align: center;
        height: 120px;
        width:100%;
        align-content: center;
    }
    
    .wash-head h1 {
        font-size: 70px;
        color: transparent; 
        -webkit-text-stroke: 1px whitesmoke;
        z-index: 0;
        pointer-events: none;
        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif; 
        letter-spacing: 10px; 
        white-space: nowrap; 
        margin: 0;
    }
    .creami-head{
        height: 120px;
        align-content: center;
    }
    .creami-head h1 {
        font-size: 70px;
        color: transparent; 
        -webkit-text-stroke: 1px whitesmoke;
        z-index: 0;
        pointer-events: none;
        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif; 
        letter-spacing: 10px; 
        white-space: nowrap; 
        text-shadow: 1px 1px 2px;
        text-align: center;
        margin: 0;
    }
     .services-contact-form li {
        padding: 5px;
        justify-content: justify;
        font-family: 'Mulish', sans-serif;
    }
    .headlamp-head{
        height: 120px;
        align-content: center;
    }
    .headlamp-head h1{
        font-size: 70px;
        color: transparent; 
        -webkit-text-stroke: 1px whitesmoke;
        z-index: 0;
        pointer-events: none;
        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif; 
        letter-spacing: 10px; 
        white-space: wrap; 
        text-shadow: 1px 1px 2px;
        text-align: center;
        margin: 0;
    }
    .services-contant {
        display: flex;
        flex-wrap: wrap;
        background-color: #000000;
        width: 964px;
        height: 343px;
        margin: 1% 0;
        margin-bottom: 120px;
    }
    .services-contact-image {
        flex: 1; 
        width: 50%;
    }
    
    .services-contact-image img {
        width: 370px; 
        height: 320px;
        border-radius: 20px;
    }
    .services-contact-form h2 {
        font-size: 40px;
        margin-bottom: 20px;
        background: linear-gradient(90deg,  #FF6B00 0%, #FFBE00  100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 0;
    }
    .services-contact-form h3 {
        color: #FF6B00;
    }
    .services-contact-form li{
        font-size:16px;
        width: 80%;
        color: white;
    }
    .services-contact-form{
        color: white;
        width: 551px;
        margin-bottom: 50px;
        margin-left: 0px;
    }
    .midd-contant {
        display: flex;
        /* flex-wrap: wrap; */
        background-color: #000000;
        padding: 20px;
        width: 90%;
        border-bottom:2px solid #FFFFFF ;
        justify-content: space-around;
    }
    .midd-contact-image {
        width: 17.5%;
    }
    
    .midd-contact-image img {
        width: 200px; 
        height: 180px;
        border-radius: 20px;
    }
    .midd-contact-form h2 {
        font-size: 40px;
        margin-bottom: 10px;
        margin-left: 20px;
        background: linear-gradient(90deg,  #FF6B00 0%, #FFBE00  100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .midd-contact-form li{
        font-size:16px;
        width: 80%;
        margin-left: 50px;
        color: white;
        margin-bottom: 10px;
    }
    .midd-contact-form{
        color: white;
        width: 600px;
        margin-bottom: 50px;
    }
    .Interior-head{
        text-align: center;
        height: 120px ;
        align-content: center;
    }
    
    .Interior-head h1 {
        font-size: 70px;
        color: transparent; 
        -webkit-text-stroke: 1px whitesmoke;
        z-index: 0;
        pointer-events: none;
        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif; 
        letter-spacing: 10px; 
        white-space: nowrap; 
        text-shadow: 1px 1px 2px;
        margin: 0;
        align-items: center;
    }
    .engine-head h1{
        font-size: 70px;
        color: transparent; 
        -webkit-text-stroke: 1px whitesmoke;
        z-index: 0;
        pointer-events: none;
        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif; 
        letter-spacing: 10px; 
        white-space: nowrap; 
        text-shadow: 1px 1px 2px;
        text-align: center;
        margin: 0;
    }
    .Wind-head{
        text-align: center;
        height: 120px;
        align-content: center;
    }
    .Wind-head h1{
    font-size: 70px;
    color: transparent; 
    -webkit-text-stroke: 1px whitesmoke;
    z-index: 0;
    pointer-events: none;
    text-transform: uppercase;
    font-family: 'Chakra Petch', sans-serif; 
    letter-spacing: 10px; 
    white-space: nowrap; 
    text-shadow: 1px 1px 2px;
    text-align: center;
    margin: 0;
    }
    p{
        font-size: 16px;
        color: white;
        width: 88%;
        justify-content: justify;
        font-family: 'Mulish', sans-serif;
    }
    .add-cart {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 52px;
        margin-left: -50px;
        margin-top: 10px;   
     }
     .add-ppf-cart{
        margin-top: 270px;
        margin-left: 100px;
        width: 500px;
     }
     .add-creamic-cart{
        margin-top: 550px;
        margin-left: 100px;
        width: 500px;
     }
     .add-wind-cart{
        margin-top: 50px;
        margin-left: 100px;
     }
.added{
    padding: 10px 20px;
    font-size: 14px;
    background: transparent;
    color: #fff;
    border-radius: 20px;
    margin: 5px;
    white-space: nowrap;
    transition: background-color 0.3s ease;
    width: 277px;
    height: 58px;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.added-active {
    background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
    border: none;
}

.cencel {
    border: 3px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-radius: 50%;
    font-size: 30px; 
    cursor: pointer; 
    padding: 5px 15px; /* Add some padding */
    color: #FF6B00; /* Change text color for contrast */
    position: relative; /* Allows for pseudo-elements */
    width: 50px;
    height: 50px;
    padding-left: 12px;
}
.service-partnerarrow {
    background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%);
   color: #fff;
   width: auto;
   margin-top: 20px;
   padding: 10px;
   border-radius: 20px;
   border: none;
   display: inline-block;
   text-decoration: none; 
   float: right;
   margin-right: 100px;
   margin-bottom: 50px;
}
.service-partnerarrow:hover {
    text-decoration: none; 
}
    @media screen and (max-width: 1200px){
        .Interior-head h1 {
            font-size: 70px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            white-space: wrap; 
            text-shadow: 1px 1px 2px;
            text-align: center;
        } 
        .Interior-head{
            height:100px;
            width: auto;
            align-content: center;
        }
        .engine-head{
            height: 100px;
            align-content: center;
        }
        .engine-head h1{
            font-size: 65px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            white-space: nowrap; 
            text-shadow: 1px 1px 2px;
            text-align: center;
            margin: 0;
        }
        .Wind-head{
            text-align: center;
            height: 150px;
            align-content: center;
        }
        .Wind-head h1{
        font-size: 60px;
        color: transparent; 
        -webkit-text-stroke: 1px whitesmoke;
        z-index: 0;
        pointer-events: none;
        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif; 
        letter-spacing: 10px; 
        white-space: nowrap; 
        text-shadow: 1px 1px 2px;
        text-align: center;
        margin: 0;
        }

    }
    @media screen and (max-width: 768px) {
        .main-contant{
            /* width: 100vw; */
            height: auto;
        }
        p{
            font-size: 16px;
            margin-left: 20px;
            color: white;
            width: 88%;
            justify-content: justify;
        }
        .wash-head{
            height: 100px;
            text-align: center;
        }
        .wash-head h1 {
            font-size: 50px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            white-space: nowrap; 
            text-shadow: 1px 1px 2px;
            padding-top: 20px;
            text-align: center;
        }
        .midd-contant {
            display: flex;
            flex-wrap: wrap;
            background-color: #000000;
            padding: 20px;
            width: 90%;
            margin: 5% auto;
        }
        .midd-contact-image {
            flex: 1; 
            width: 50%;
        }
        
        .midd-contact-image img {
            width: 100%; 
            height: 250px;
            border-radius: 20px;
        }
        .services-contact-image img {
            width: 100%; 
            height: 250px;
            border-radius: 20px;
        }
        .services-contact-form h3 {
            margin-left: 10px;
        }
        .services-contact-form li{
            font-size:14px;
            width: 99%;
            margin-left: 10px;
        }
        .midd-contact-form h2 {
            font-size: 25px;
            margin-bottom: 20px;
            margin-left: 10px;
        }
        .services-contact-form h2 {
            font-size: 25px;
            margin-bottom: 20px;
            margin-left: 25px;
        }
    
        .midd-contact-form li{
            font-size:14px;
            width: 80%;
            margin-left: 20px;
            color: white;
            margin-bottom: 10px;
        }
        .midd-contact-form{
            color: white;
            width: 50%;
            margin-bottom: 50px;
        }
        .creami-contact-image img {
            width: 100%; 
            height: 250px;
            border-radius: 20px;
        }
    
        .Interior-head{
            height:100px;
            width: auto;
        }
        .Interior-head h1 {
            font-size: 35px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            white-space: wrap; 
            text-shadow: 1px 1px 2px;
            text-align: center;
        } 

        .Wind-head{
            text-align: center;
            height: 100px
        }
        .Wind-head h1{
        font-size: 30px;
        color: transparent; 
        -webkit-text-stroke: 1px whitesmoke;
        z-index: 0;
        pointer-events: none;
        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif; 
        letter-spacing: 10px; 
        white-space: wrap; 
        text-shadow: 1px 1px 2px;
        text-align: center;
        } 
        .engine-head{
            height: 100px;
            align-content: center;
        }
        .engine-head h1{
            font-size: 35px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            white-space: wrap; 
            text-shadow: 1px 1px 2px;
            text-align: center;
        }
        .headlamp-head{
            height: 100px;
            align-content: center;
        }
        .headlamp-head h1{
            font-size: 35px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            white-space: wrap; 
            text-shadow: 1px 1px 2px;
            text-align: center;
        }
        .creami-head{
            height: 100px;
            align-content: center;
        }
        .creami-head h1 {
            font-size: 25px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            text-shadow: 1px 1px 2px;
            text-align: center;
        }
        .creami-contact-form h3 {
            margin-left: 10px;
        }
    
        .creami-contact-form li{
            font-size:14px;
            width: 99%;
            margin-left: 10px;
        }
        .service-partnerarrow {
           margin-left: 200px;
        }
    }
    @media screen and (max-width: 480px) {
        .main-contant{
            width: 100vw;
            height: auto;
            margin: 0;
            border: none;
        }

        .midd-contact-form{
            color: white;
            width: 100%;
            margin-left: 0;
        }

        p{
            font-size: 16px;
            color: white;
            width: 88%;    
            justify-content: justify;
           }
        .wash-head{
            height: 100px;
            text-align: center;
        }
        .wash-head h1 {
            font-size: 40px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            white-space: nowrap; 
            text-shadow: 1px 1px 2px;
            padding-top: 20px;
            text-align: center;
        }
        
    .creami-contant {
            display: flex;
            flex-direction: column;
            align-items: left;
            margin: 0;
            width: auto;
        }
        .creami-contact-image {
            width:100%; 
            margin-bottom: 20px;
            align-items: center ;    
        }
        .creami-contact-image img {
            width: 95%; 
            height: 200px;
            border-radius: 20px;
        }
        .creami-contact-form h2{
            align-items: center;
            font-size: 20px;
            margin-left: 10px;
        }
        .creami-contact-form{
            color: white;
            width: 100%;
            margin-left: 0;
        }
    
    
        .midd-contant {
            display: flex;
            flex-direction: column-reverse;
            align-items: left;
            margin: 0;
            width: auto;
        }
        .midd-contact-image {
            width:100%; 
            margin-bottom: 20px;
            align-items: center ;    
        }
        .midd-contact-image img {
            width: 100%; 
            height: 200px;
            border-radius: 20px;
        }
        .midd-contact-form h2{
            align-items: center;
            font-size: 20px;
            margin-left: 10px;
        }
        .midd-contact-form{
            color: white;
            width: 100%;
            margin-left: 0;
        }
    
        .Interior-head{
            height:100px;
            width: auto;
        }
        .Interior-head h1 {
            font-size: 35px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            white-space: wrap; 
            text-shadow: 1px 1px 2px;
        
            text-align: center;
        } 
        .Wind-head{
            text-align: center;
            height: 100px;
            width:100%;
        }
        .Wind-head h1{
        font-size: 20px;
        color: transparent; 
        -webkit-text-stroke: 1px whitesmoke;
        z-index: 0;
        pointer-events: none;
        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif; 
        letter-spacing: 10px; 
        white-space: wrap; 
        text-shadow: 1px 1px 2px;
        text-align: center;
        } 
        .engine-head{
            height: 100px;
            align-content: center;
        }
        .engine-head h1{
            font-size: 35px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            white-space: wrap; 
            text-shadow: 1px 1px 2px;
            text-align: center;
        }
        .headlamp-head{
            height: 100px;
            align-content: center;
        }
        .headlamp-head h1{
            font-size: 35px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            white-space: wrap; 
            text-shadow: 1px 1px 2px;
            text-align: center;
        }
        .creami-head{
            height: 100px;
            align-content: center;
        }
        .creami-head h1 {
            font-size: 30px;
            color: transparent; 
            -webkit-text-stroke: 1px whitesmoke;
            z-index: 0;
            pointer-events: none;
            text-transform: uppercase;
            font-family: 'Chakra Petch', sans-serif; 
            letter-spacing: 10px; 
            text-shadow: 1px 1px 2px;
            text-align: center;
            white-space: wrap;
        }
        .main-contant h2 {
            white-space: wrap; 
            margin-left: 0;
        }

        .services-contant{
            display: flex;
            flex-direction: row;
            align-items: left;
            margin: 0;
            width: auto;
            padding: 20px;
        }
        .services-contact-image {
            width:100%; 
            margin-bottom: 20px;
            align-items: center ;    
            display: flex;
            flex-direction: column;
        }
        .services-contact-image img {
            width: 100%; 
            height: 200px;
            border-radius: 20px;
        }
        .services-contact-form h2{
            align-items: center;
            font-size: 20px;
            margin-left: 10px;
        }
        .services-contact-form{
            color: white;
            width: 100%;
            margin-left: 0;
        }
        .service-partnerarrow {
            margin-right: 20px;
            white-space: nowrap;
            width: auto;
         }
         .add-cart {
            float: right;
             
         }
         .add-ppf-cart{
            margin-top: 20px;
            margin-left: 10px;
            width: 300px;
            display: flex;
            flex-direction: column;
         }
         .add-creamic-cart{
            margin-top: 20px;
            margin-left: 50px;
            width: 300px;
         }
         .added{
            width: 150px;
            height: 40px;
            margin: 0 auto;
         }
         .cencel{
            width: 40px;
            height: 40px;
            font-size: 25px;
            padding: 0;
         }
    }

    