.header{
  position: sticky;
  z-index: 20;
  top: 0;
}
.navbar-ajs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000;
  color: #fff;
  height: 104px;
}
.desktop-menu-web {
  padding: 10px;
  gap: 30px;
  align-items: center;
}
.active-icon{
  color: #FF6B00 ;
  margin-left: 5px;
}
.inactive-icon {
  color: #FFBE00;
  margin-left: 5px;
}
.navbar-logo-ajs {
  display: flex;
  align-items: center; 
}
.navbar-logo-ajs a {
  font-size: 1.7rem;
  color: #fff; 
  text-decoration: none;
  font-weight: bolder;
  font-family: 'Poppins', sans-serif;
}

.navbar-logo-ajs img {
  width: 63px;
  height: 62px;
  margin-left: 96px;
}
.navbar-logo-ajs img:hover {
  animation: spin 1s linear forwards; 
  transition: transform var(--ifm-transition-fast) var(--ifm-transition-timing-default);
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.xyz {
  display: flex;
  align-items: center; /* optional, adjusts vertical alignment */
  justify-content: space-between; /* optional, adjusts horizontal alignment */
}

.xyz li {
  list-style: none; /* removes the bullet points */
}
.location-text{
  color: #fff;
}

.xyz .location-dropdown {
  width: 80px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 25px;
  font-size: 18px;
  gap: 5px;
  font-weight: none;
  padding-left: 15px;
  padding: 9px 20px;
  transition: background 0.3s;
  border: 2px solid transparent;
  border-radius: 25px;
  background: linear-gradient(to right, #000, #000), 
              linear-gradient(to right,#FF6B00, #FFBE00 ); 
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #000;
  font-family: 'Poppins', sans-serif;
}
.xyz .location-dropdown:hover{
  background: linear-gradient(to right, #000, #000), 
              linear-gradient(to right, #FFBE00 ,#FF6B00 ); 
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #000;
  }
  .location-icon img {
    width: 18px;
    height: 22.91px;
  }
  
.location-icon {
  margin-right: 1px;
  color: #FFBE00;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  margin-left: 2px;
  margin-top: 10px;
  color: #fff;
  background-color:none;
  z-index: 1000;
  width: 150px;
  padding: 0px 0px;
  padding-left: 10px;
  /* border-radius: 20px; */
  background: linear-gradient(170deg,   #bb780b 0%, #6F3800 60%, #bb780b 100%,#9c876a 0%); 
  border-radius: 20px;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;

}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  /* color: #161616;/* Old hover color */
  /* background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%);  */
  border-radius: 0px;
}

.dropdown-item {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  justify-content: space-between; /* Space between the text and icon */
  padding: 8px 12px; 
}
.icon-right {
  opacity: 0; /* Hide the icon initially */
  transition: opacity 0.3s ease;
  margin-left: 50px;
  color: #FFBE00;
}
.icon-right img {
  width: 20px;
}

.location-label:hover .icon-right {
  opacity: 1; /* Show the icon on hover */
}

.mobile-menu-icon-ajs {
  display: none;
  font-size: 24px;
  cursor: pointer;
}
/* Mobile Sidebar Styles */
.mobile-sidebar {
  position: fixed;
  top: 10%;
  right: -100%; 
  width: 35%;
  max-height: 100vh; 
  background-color: #333;
  transition: left 0.3s ease-in-out;
  z-index: 999;
  padding: 20px;
  overflow-y: auto; 
  border-radius: 20px;
}

.mobile-sidebar.open {
  right: 0; 
}
.mobile-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu li {
  margin: 10px 0;
}

.mobile-menu a {
  color: white;
  text-decoration: none;
  font-size: 12px;
}
.mobile-menu li :hover{
  color: #FFBE00;
}
.dropdown-ajs a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dropdown-menu-ajs {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}
.dropdown-item-ajs {
  margin-left: 40px;
}
.nested-dropdown-menu-ajs {
  margin-left: 20px;
}
.navbar-contact-ajs {
  padding: 16px 20px;
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
  color: white;
  border: none;
  border-radius: 48px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  width: 110px;
}
.navbar-contact-ajs:hover {
  background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%);
}
@media (max-width: 1024px) {
  .navbar-ajs{
    width:auto;
  }
  .navbar-contact-ajs{
    width: auto;
    font-size: 12px;
    white-space: wrap;
  }
  .desktop-menu-web li {
    position: relative;
    margin: 0 ;
    white-space: nowrap;
  }
  .navbar-logo-ajs img {
    width: 63px;
    height: 62px;
    margin-left: 0;
  }
}
@media (max-width: 768px) {

  /* .navbar-ajs{
    width:100%;
  } */

  .mobile-menu-icon-ajs {
    display: block;
    margin-right: 15px;
  }

  .desktop-menu-web {
    display: none;
  }

  /* Disable hover for mobile view */
  .desktop-menu-web .dropdown-ajs:hover .dropdown-menu-ajs,
  .desktop-menu-web .dropdown-item-ajs:hover .nested-dropdown-menu-ajs {
    display: none;
  }
  .xyz .dropdown-web{
    display: none;
  }
  .dropdown-menu{
    width: 120px;
  }
}

/* General Menu Styling */
.desktop-menu-web {
  list-style: none;
  padding: 5px;
  margin: 0;
  display: flex;
  gap: 20px;
  font-family: "Chakra Petch", sans-serif;
  background-color: rgba(34, 38, 38, 0.8); /* #222626 with 80% opacity */
border-radius: 20px;  
}

.desktop-menu-web li {
  position: relative;
}

/* Links */
.desktop-menu-web li a {
  text-decoration: none;
  color: #fff;
  padding: 10px 20px;
  display: block;
  font-weight: bold;
  transition: color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;

}

.desktop-menu-web li a:hover {
  color:#FFBE00; /* Hover color */
}

/* Dropdown Icon Styling */
.active-icon, .inactive-icon {
  margin-left: 5px;
  transition: transform 0.3s;
}

.active-icon {
  transform: rotate(180deg); /* Rotate when active */
}

.inactive-icon {
  transform: rotate(0deg);
}

/* General Sub-item Styling */
.sub-item-web a {
  color: #fff;
  padding: 8px 12px;
  display: block;
  font-size: 0.9em;
  white-space: nowrap;
  transition: color 0.3s;
  list-style: none;
  background: linear-gradient(170deg,   #bb780b 0%, #6F3800 60%, #bb780b 100%,#9c876a 0%); 
  border-radius: 10px;
}

.sub-item-web a:hover {
  color: #FFBE00;
}

/* Submenu Styling */
.sub-menu-web {
  display: none;
  position: absolute;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  list-style: none;
  top: 100%;
  left: 0;
  min-width: 200px;
  z-index: 999;
}

.sub-menu-web li {
  margin-bottom: 5px;
  list-style: none;
}

.sub-menu-web li:last-child {
  margin-bottom: 0;
}

/* Show Submenu on Dropdown Activation */
.dropdown-web:hover .sub-menu-web,
.dropdown-web.active .sub-menu-web {
  display: block;
}

/* Nested Dropdown Styling */
.inside-menu {
  display: none;
  position: absolute;
  padding-left: 15px;
  background-color: transparent;
  margin-top: -10px; /* Adjust as needed to align with the top of the parent submenu */
  border-left: 2px solid #FFBE00;
  border-radius: 5px;
  min-width: 150px; /* Adjust width as needed */
  left: 100%; /* Position to the right of the parent submenu */
  top: 0; /* Align with the top of the parent submenu */
  z-index: 1000; /* Ensure it appears above other content */
}

.sub-item-web:hover .inside-menu,
.sub-item-web.active .inside-menu {
  display: block;
}

.inside-menu li {
  padding: 2px 0;
}

.inside-menu li a {
  font-size: 0.8em;
}

.inside-menu li a:hover {
  color: #FFBE00;
}

@media (min-width: 769px) {
  /* Ensure styles only apply to larger screens */

  .desktop-menu-web {
    flex-direction: row;
    gap: 70px;
    align-items: center;
  }

  .desktop-menu-web li {
    width: auto;
  }

  .desktop-menu-web li a {
    padding: 10px 10px;
  }

  /* Dropdown and Nested Dropdown should work with hover on larger screens */
  .sub-menu-web, .inside-menu {
    display: none;
  }

  .dropdown-web:hover .sub-menu-web,
  .dropdown-web.active .sub-menu-web {
    display: block;
  }

  .sub-item-web:hover .inside-menu,
  .sub-item-web.active .inside-menu {
    display: block;
  }
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .desktop-menu-web {
    display: none; 
  }
}
