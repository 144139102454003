/* Main Container */
.treatment-table-container {
  position: relative;
    max-width: 100%;
    margin: 20px auto; /* Center the container */
    padding: 20px;
    background: linear-gradient(170deg,   #bb770bca 0%, #6f370053 60%, #bb780b 100%,#9c876a 0%); /* Adjusted gradient without black */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 90%; /* Use a percentage for responsiveness */
    z-index: 2;
    margin-top: -300px;
  }
  
  /* Treatment Overview Section */
  
  /* Treatment Details */
  .treatment-details {
    display: flex;
    flex-wrap: wrap; /* Wrap on smaller screens */
    justify-content: space-between; /* Space between sections */
  }
  
  /* Treatment Detail Styles */
  .treatment-detail {
    flex: 1; /* Equal space for all details */
    margin: 10px; /* Margin around details */
    padding: 15px;
    border-radius: 5px; /* Rounded corners */
    min-width: 220px;
    color: #fff; /* Minimum width for responsiveness */
    position: relative;
    border-right: 2px solid #fff ;
    align-items: center;    
  }
  .treatment-detail-first {
    flex: 1; /* Equal space for all details */
    margin: 10px; /* Margin around details */
    padding: 15px;
    border-radius: 5px; /* Rounded corners */
    min-width: 220px;
    color: #fff; /* Minimum width for responsiveness */
    position: relative;
    border-right: 2px solid #fff /* Vertical line */
  }
  .treatment-detail-first p{
    margin-bottom: 30px;
    text-align: center;
  }
  
  /* Remove the line from the last element */
  .treatment-detail:last-child {
    border-right: none;
  }
  
  /* Header Styles */
  
  /* Detail Headers */
  .treatment-detail h5 {
    font-size: 18px; /* Increase font size for detail headers */
    margin-bottom: 10px; /* Space below detail headers */
    color: #fff; /* White text for better contrast */
    width: 60%;
    margin-left: 20%;
    background: linear-gradient(90deg, #6F3800 0%, #bb770b3a 60%, #6f370034 100%); /* Adjusted gradient without black */
    padding: 5px; /* Adding padding to make it look better */
    border-radius: 5px;
    text-align: center;
  }
  .treatment-detail-first h5{
    font-size: 18px; /* Increase font size for detail headers */
    margin-bottom: 10px; /* Space below detail headers */
    color: #fff; /* White text for better contrast */
    width: 60%;
    margin-left: 20%;
    background: linear-gradient(90deg, #6F3800 0%, #bb780b 60%, #6f3700 100%); /* Adjusted gradient without black */
    padding: 5px; /* Adding padding to make it look better */
    border-radius: 5px;
  }
  /* Paragraph Styles */
  .treatment-detail p {
    font-size: 14px; /* Standard font size for paragraphs */
    line-height: 1.6; /* Increase line height for readability */
    color: #fff; /* Slightly lighter color for paragraph text */
    text-align: center;
    width: 100%;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .treatment-table-container {
      overflow-x: auto; /* Enable horizontal scrolling on small screens */
      margin: 0 auto;
      width: auto;

    }
  
    .treatment-details {
      flex-direction: column; /* Stack details vertically on small screens */
      align-items: center;
    }
    .treatment-detail-first{
      width: auto; /* Make details full width on small screens */
      margin: 0; /* Adjust margin for small screens */
      border-right: none; /* Remove the border on small screens */
      border-bottom: 2px solid #fff; /* Horizontal line on small screens */
      padding: 0; 
    }
    .treatment-detail {
      width: auto; /* Make details full width on small screens */
      margin: 0; /* Adjust margin for small screens */
      border-right: none; /* Remove the border on small screens */
      border-bottom: 2px solid #fff; /* Horizontal line on small screens */
      padding: 0;
    }
    .treatment-detail-first p,
    .treatment-detail p{
      font-size: 14px;
      margin-left: 0;
    }
    /* Remove border from last child */
    .treatment-detail:last-child {
      border-bottom: none;
    }
  }
  