  .customer-details-page {
    display: flex;
    border-top:2px solid #FFFFFF ;

  }
  
  .Status-sidebar {
    background-color: #000000;
    min-width: 220px;
    height: 100%;
    padding: 0;
  }
  
  .Status-sidebar h3 {
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .Status-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .Status-sidebar  li {
    display: flex;
    color: #fff;
    margin: 10px 0;
    padding: 10px;
    border-left: 3px solid transparent;
    cursor: pointer;
    align-items: center;
    font-family: "Poppins", sans-serif;
  }
  
  .Status-sidebar ul li:hover {
    border-left: 3px solid #ffcc00;
  }
  .Status-sidebar li.active {
    color: #FFBE00; /* Active color */
  }
  .details-form {
    flex: 1;
    padding: 40px;
    border-left: 2px solid #FFFFFF;
  }
  
  .details-form h1 {
  background: linear-gradient(60deg,#FF6B00 -20%,  #FFBE00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
  }
  
  .customer-input-group {
    margin-bottom: 20px;
  }
  
  .customer-input-group label {
    margin-bottom: 8px;
    font-size: 16px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    gap: 5px;
  }
  .Customer-column img {
    width: 21.15px;
    height: 20px;
  }
  .customer-input-group input {
    width: 100%;
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 25px;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
  }  
  .customer-input-group input:hover{
    border: 2px solid transparent;
    border-radius: 25px;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FFBE00, #FF6B00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;
  }
  .customer-input-group input:focus {
    outline: none; /* Removes the default blue outline */
    box-shadow: 0 0 10px rgba(255, 190, 0, 0.8);
    border: 2px solid #FFBE00;
  }
  .Customer-row{
    display: flex;
    gap: 50px;

  }
  .Customer-row input::placeholder{
    color: #ffffffa8;
    font-family: 'Poppins', sans-serif;
  }
  .Customer-row input {
    width: 250px;
    height: 25px; 
  }
  .drop-address-input {
    width: 572px !important; 
    height: 40px !important; 
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #FFBE00;
  }
  .landmark-input {
    width: 250px !important; 
    height: 35px; 
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #FFBE00;
  }
  .Customer-submit-btn {
    display: flex;
    background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%);
    color: #fff;
    padding: 15px 30px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    float: right;
  }
  .submit-btn:hover {
    background-color: #ff9900;
  }
  

  