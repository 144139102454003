.faq-section {
    color: orange;
    width: 875px;
    align-items: center;
    margin: 0 auto;
  }
  .faq-section li {
    list-style: none;
  }
  .faq-section h1 {
    font-size: 48px;
    text-align: center;
    background: linear-gradient(90deg,  #FF6B00 0%, #FFBE00  100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .faq-list {
    margin-top: 20px;
  }
  
  .faq-item {
    border: 3px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                  linear-gradient(to right,#FFBE00, #FF6B00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-radius: 16px;
    padding: 16px 20px;
    margin-bottom: 15px;
    background-color: black;
    color: #fff;
    box-shadow: 0 0 10px rgba(252, 237, 192, 0.637);
    width: 875px;

  }
  .faq-question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-size: 1.2em;
    align-items: center;
    height: 40px;
    font-family: 'Poppins', sans-serif;
  }
  
  .faq-answer {
    font-size: 0.9em;
    color: #ffbf00d7;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    line-height: 24px;

  }
  
  .faq-icon {
    font-size: 1.2em;
  }
  .still-have-question{
 text-align: center;
 margin-bottom: 40px;
 font-family: 'Poppins', sans-serif;
  }
  .still-have-question p{
margin: 0 auto;
margin-bottom: 10px; 
 }
 .still-have-question a {
  text-decoration: underline;
  color: #FFBE00;
  font-weight: bold;
 }


@media only screen and (max-width: 768px) {
  .faq-section {
    padding: 0 20px; 
    width: 80%;
  }

  .faq-item {
    padding: 12px 16px; 
    margin-bottom: 10px;
  }

  .faq-question {
    font-size: 1em; 
    height: auto; 
  }

  .faq-answer {
    font-size: 0.8em; 
  }
}

@media only screen and (max-width: 480px) {
  .faq-item {
    padding: 10px 14px; 
  }

  .faq-question {
    font-size: 0.9em; 
  }

  .faq-answer {
    font-size: 0.75em;
  }
}