.home-bg {
  display: flex;
  flex-direction: column;
  height: 1016px;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
  background-image: url('../image/Desktop65.png'); /* Correct image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home-bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 10px;
  pointer-events: none;
  z-index: 2; /* Ensure the border is above the background image */
  box-shadow: 0 0 50px 10px rgba(0, 0, 0, 0.8); /* Blurs all sides */
  filter: blur(5px); /* Blur the shadow to give the border a blurred effect */
}

.home-bg > * {
  position: relative;
  z-index: 3; 
}

.hero-content {
  display: flex;
  flex-direction: column; 
  text-align: left; 
  gap: 10px;
  margin-top: 500px;
}

.hero-content h1 {
  font-family: 'Chakra Petch', sans-serif;
  font-size: 50px;
  margin-bottom: 10px;
  color: #fff;
  margin-left: 50px;
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-content p {
  font-size: 24px;
  margin: 0;
  color: #FFF;
  font-family: 'Poppins', sans-serif;
  margin-left: 50px;
  font-style: italic;
}

.animated-heading, .animated-paragraph {
  opacity: 0;
  transform: translateY(30px) scale(0.8);
  animation: slideScaleUp 1s forwards;
}

.animated-heading {
  animation-delay: 0.5s;
}

.animated-paragraph {
  animation: fadeInUp 2s forwards;
  animation-delay: 1.5s;
}

@keyframes slideScaleUp {
  0% {
      opacity: 0;
      transform: translateY(30px) scale(0.8);
  }
  100% {
      opacity: 1;
      transform: translateY(0) scale(1);
  }
}

@keyframes fadeInUp {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}
.homepage {
  position: relative; 
}
.search-container {
  display: flex;
  align-items: center;
  z-index: 10;
  border-radius: 26px;
  background: linear-gradient(170deg, 
  rgba(187, 120, 11, 0.6) 0%,  
  rgba(111, 56, 0, 0.6) 60%, 
  rgba(187, 120, 11, 0.6) 100%,  
  rgba(156, 135, 106, 0.6) 0%  
); 
  width: 500px;
  height: 50px;
  position: relative; 
  margin: 0 auto;
  border-top: 1px solid#633102;
  border-bottom: 1px solid#633102;
}

.search-input {
  border: none;
  background: transparent;
  padding: 10px;
  border-radius: 30px;
  outline: none;
  flex: 1;
  background: transparent;
  font-size: 13px;
  color: white;
  cursor: initial;
  font-size: 15px;
}
.search-input::placeholder {
  color: white;
  opacity: 1; 
}

.search-button {
  border: none;
  cursor: pointer;
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  height: 50px;
  width: 50px;
}

.search-button:hover {
  background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%);
}

.suggestions-list {
  position: absolute;
  top: 100%;
  right: 9%;
  width: 85%;
  border: none;
  background: #161616;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  font-family: 'Chakra Petch', sans-serif;
  box-shadow:0px 8px 15px -5px rgba(94, 94, 93, 0.5),  /* Main shadow */
  0px 10px 30px -10px rgba(243, 240, 240, 0.3), /* Deeper shadow */
  0px 20px 40px -15px rgba(236, 234, 234, 0.2); /* Softest shadow */
}
.suggestions-list::-webkit-scrollbar {
  width: 8px;
}

.suggestions-list::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%);  border-radius: 10px;
  border-radius: 10px;
  height: 20px;
}

.suggestions-list::-webkit-scrollbar-track {
  background: #161616;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
  font-family: 'Chakra Petch', sans-serif;
  color: #fff;
  text-transform: capitalize;
}

.suggestions-list li:hover {
  background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%); 
}

.dropdown-arrow{
  color: #f5f5f5;
  font-size: 10px;
}
@media (max-width: 1200px){
  .search-container {
    margin-top: 35px;
  }
}
@media (max-width: 768px){
  .search-container {
    margin-top: 150px;
    margin-bottom: 20px;
  }
}
@media (max-width: 480px) {
  .search-container {
    display: flex;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 130px;
    z-index: 10; 
    width: 280px;
  }
  .search-input{
    width: 30vw;
    font-size: 12px;
  }
  .suggestions-list{
    max-height: 130px;
    right: 5%;
    background-color: #111;
  }
  .hero-section {
    flex-direction: column; /* Stack content and image vertically */
  }

  .hero-content,
  .hero-image,
  .hero-image2 {
    flex: none; 
    padding: 10px;
  }

  .hero-content {
    order: 1; /* Make sure content appears first */
  }

  .hero-image,
  .hero-image2 {
    order: 2; /* Make sure image appears below content */
  }

  .hero-content h1 {
    font-size: 30px;
    margin-left: 10px;
  }

  .hero-content p {
    font-size: 16px;
    margin-left: 10px;
  }
  
  .pagination-dots11 {
    text-align: center;  
    margin-top: 0px;
  }
  
}

@media (min-width: 481px) and (max-width: 768px) {
  .hero-section {
    flex-direction: column; /* Stack content and image vertically */
  }

  .hero-content,
  .hero-image,
  .hero-image2 {
    flex: none; /* Reset flex property for medium screens */
    padding: 15px;
  }

  .hero-content {
    order: 1; /* Content appears first */
  }

  .hero-image,
  .hero-image2 {
    order: 2; /* Image appears below content */
  }

  .hero-content h1 {
    font-size: 40px;
  }

  .hero-content p {
    font-size: 18px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .hero-section {
    flex-direction: row; /* Keep content and image side by side */
  }

  .hero-content,
  .hero-image,
  .hero-image2 {
    flex: 1;
    padding: 20px;
  }

  .hero-content {
    order: 1; /* Content appears first */
  }

  .hero-image,
  .hero-image2 {
    order: 2; /* Image appears second */
  }

  .hero-content h1 {
    font-size: 45px;
  }

  .hero-content p {
    font-size: 19px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .hero-section {
    flex-direction: row; /* Keep content and image side by side */
  }

  .hero-content,
  .hero-image,
  .hero-image2 {
    flex: 1;
    padding: 20px;
  }

  .hero-content {
    order: 1; /* Content appears first */
  }

  .hero-image,
  .hero-image2 {
    order: 2; /* Image appears second */
  }

  .hero-content h1 {
    font-size: 50px;
  }

  .hero-content p {
    font-size: 20px;
  }
}
