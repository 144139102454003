::-webkit-scrollbar {
  width: 10px; 
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #161616;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%); 
  border-radius: 10px;
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(90deg,#FFBE00 0%, #FF6B00 100%); }

.scrollbar {
  scrollbar-width: thin; 
  scrollbar-color: #FFBE00 #161616; 
}
