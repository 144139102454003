/* PartnerSidebar.css */
.icon-active {
    color: #FFBE00; /* Active icon color */
  }
  
  .icon-inactive {
    color: #fff; /* Inactive icon color */
  }
  
  .Status-sidebar {
    /* Add any additional styles for the sidebar here */
  }
  