.container-part {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
}
.Status-sidebar {
    background-color: #000000;
    padding: 20px;
    min-width: 250px;
    height: 100%;
  }
  
  .Status-sidebar h3 {
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .Status-sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .Status-sidebar ul li {
    color: #fff;
    margin: 10px 0;
    padding: 10px;
    border-left: 3px solid transparent;
    cursor: pointer;
  }
  
  .Status-sidebar ul li:hover {
    border-left: 3px solid #ffcc00;
  }
  .submit-bt {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2em;
    background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
    color: white;
    border: none;
    width: 142px;
    height: 56px;
    cursor: pointer;
    margin-right: 20px;
    border-radius: 16px;
    margin-bottom: 100px;
    font-family: 'Chakra Petch', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between text and icon */
  }
  
  .submit-bt.go-back {
    border-radius: 16px;
    box-sizing: border-box;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;   
    color: #fff;
    white-space: nowrap;
  }
  
  .next-page {
    display: flex;
    justify-content: end;
    margin-right: 20px;
    border-top: 2px solid gray;
  }
  
  .submit-bt .fa-arrow-left, .submit-bt .fa-arrow-right {
    font-size: 1.2em;
  }
  
/* Form container styles */
.form-container-part {
    position: absolute;
    top: 0;
    right: 15px;
    width: 75%;
    height: 100%;
    color: white;
    background-color:rgba(0, 0, 0, 0.6);
    padding: 20px;
    box-shadow: -3px 0px 15px rgba(0, 0, 0, 0.2);
    z-index: 10;
    overflow-y: auto;
    border-left: 2px solid #FFBE00;
}
.form-container-part h3{
  background: linear-gradient(  #FF6B00 20%,#FFBE00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 35px;
}
.beInput {
    display: flex;
    flex-direction: column;
  }

.two{
    display: flex;
    gap:100px;
    width: 75%;
}

/* Form heading styles */
.form-container-part h2 {
    margin-top: 0;
    color: white;
    font-Family:Chakra Petch, sans-serif;
    box-sizing: border-box;
}

/* Form field styles */
.form-container-part form div {
    margin-bottom: 5px;
}

.form-container-part label {
    font-weight: bold;
    margin-bottom: 5px;
    font-Family:Mulish, sans-serif;
    color: #fff;
    box-sizing: border-box;
}
.form-container-part input{
    font-size: 16px;
}

.form-container-part textarea{
    font-size: 16px;
}

.form-container-part input[type="text"] {
    width: 444px;
    height: 56px;
    padding: 10px;
    border-radius: 42px;
    box-sizing: border-box;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;   
    color: #fff;
}
/* CSS for Store Address-1 */
.form-container-part input[name="registeredAddress"].address-1 {
    width: 86%;
    height: 84px;
}

/* CSS for Store Address-2 */
.form-container-part input[name="registeredAddress1"].address-2 {
    width: 86%;
    height: 84px;
}
.form-container-part input[name="website"].web-margin {
    margin-left: 540px;
}
.Webinput-container label{
    margin-left: 80px ;
    display: block;
}
.form-container-part input[type="number"] , 
.form-container-part input[type="file"] ,
.form-container-part input[type="tel"]
{
    width: 444px;
    height: 56px;
    padding: 10px;
    border-radius: 25px;
    box-sizing: border-box;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;   
    color: #fff;
}
.form-container-part input:focus ,.form-container-part input[name="business type"]:focus {
    outline: none; /* Removes the default blue outline */
    box-shadow: 0 0 10px rgba(255, 190, 0, 0.8);
    border: 2px solid #FFBE00;
  }
.form-container-part input[type="number"]:focus{
    background-color: white;
}
.form-container-part input[type="text"]:focus{
    background-color: white;
}

.form-container-part input[type="email"]:focus{
    background-color: white;
}

.form-container-part input[type="date"]:focus{
    background-color: white;
}

.form-container-part textarea:focus{
    background-color: white;
}
.form-container-part select:focus{
    background-color: white;
}

.form-container-part input[type="time"] {
    padding: 10px;
    border-radius: 25px;
    background-color: #ccc;
    box-sizing: border-box;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;  
    color: #fff;
}

.form-container-part input[type="time"]:focus{
    padding: 10px;
    border-radius: 25px;
    background-color: #ccc;
    box-sizing: border-box;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;  
    color: #fff;
}


.form-container-part input[type="date"] {
    width: 100%;
    padding: 10px;
    border-radius: 25px;
    background-color: #ccc;
    box-sizing: border-box;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;  
    color: #fff;
}

.form-container-part input[type="email"] {
    width: 444px;
    height: 56px;
    padding: 10px;
    border-radius: 25px;
    box-sizing: border-box;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;  
    color: #fff;
 
}
.form-container-part select:focus{
  outline: none; /* Removes the default blue outline */
  box-shadow: 0 0 10px rgba(255, 190, 0, 0.8);
  border: 2px solid #FFBE00;
}
.form-container-part select{
    width: 444px;
    height: 56px;
    padding: 10px;
    border-radius: 42px;
    box-sizing: border-box;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;  
    color: #fff;
}

.form-container-part textarea{
    width: 100%;
    padding: 10px;
    border-radius: 25px;
    box-sizing: border-box;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right,#FF6B00, #FFBE00 ); 
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-color: #000;  
    color: #fff;
}

.form-container-part P{
    margin-left: 0;
}


.form-container-part span{
    color: #FFBE00;
}


  
  .success-message-part {
    color: #5bc0de; 
    margin-top: 20px;
    font-size: 1.1em;
  }

  .error {
    color: red !important ;
    font-size: 0.875em;
  }  


  .two121{
    display: flex;
    gap: 100px;
  }
  .documentation-section {
    background-color: #000;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    padding: 20px;
  }
  h1 {
    color: #ff8c00; /* Orange color */
    font-size: 2rem;
  }
  
  .upload-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .upload-item {
    width: 654px;
    background-color: transparent;
    border: 2px solid #ff8c00;
    border-radius: 10px;
    padding: 10px 15px;
    position: relative;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px rgba(255, 140, 0, 0.7); /* Orange shadow */
  }
  
  .upload-item label {
    font-size: 1.2rem;
  }
  .upload-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #000;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .upload-toggle-icon {
    font-size: 1.5rem;
    color: orange;
  }
  .upload-section {
    padding: 10px;
    background-color: #000;
    border: 2px solid orange;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .upload-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }
  
  .upload-button {
    display: inline-flex;
    align-items: center;
    background-color: transparent;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    gap: 10px;
  }
  
  .upload-button:hover, .drive-button:hover {
    background-color: #444;
  }
  
  .uploaded-file-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    padding: 10px;
    border-radius: 5px;
  }
  
  .uploaded-file-name {
    color: #ffcc00;
  }
  
  .delete-button {
    background-color: transparent;
    border: none;
    color: #ffcc00;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
  }
  
  .delete-button:hover {
    background-color: darkred;
  }

  .upload-item input[type="file"] {
    display: none;
  }
  
  .tnc-section {
    display: flex;
    flex-direction: column;
    margin-top: 20px; /* Add spacing if needed */
  }
  
  .tnc-checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px; /* Add space between checkbox section and label */
  }
  
  .tnc-checkbox-wrapper p {
    margin: 0 10px; /* Space between checkbox and the text */
  }
  
  label {
    text-align: center;
  }
  
.beInput select option {
    padding: 10px; 
    background-color: #000000;
}

  @media screen and (max-width: 1440px) {

  /* CSS for Store Address-1 */
.form-container-part input[name="registeredAddress"].address-1 {
    width: 92%;
}

/* CSS for Store Address-2 */
.form-container-part input[name="registeredAddress1"].address-2 {
    width: 92%;
}
.form-container-part input[name="website"].web-margin {
    margin-left: 540px;
    display: block;
}
.Webinput-container label{
  margin-left: 150px ;
  display: block;
}
  }

  @media screen and (max-width: 1024px) {
    .Status-sidebar {
        min-width: 220px; /* Adjust sidebar width for this screen size */
        padding: 15px; /* Adjust padding for better spacing */
    }

    .form-container-part {
        width: 75%; /* Reduce form container width to fit screen */
        padding: 20px; /* Adjust padding for form container */
    }

    .form-container-part input[type="text"],
    .form-container-part input[type="number"],
    .form-container-part input[type="tel"],
    .form-container-part input[type="email"],
    .form-container-part input[type="date"],
    .form-container-part textarea,
    .form-container-part input[type="file"],
    .form-container-part select {
        width: 350px; /* Reduce width of inputs and other form fields */
        height: 52px; /* Adjust input height for 1024px screen */
    }
    .submit-bt {
        width: 20%; /* Adjust button width */
        height: 52px; /* Adjust button height */
    }

    .two {
        gap: 50px; /* Reduce the gap between flex items */
        flex-direction: row; /* Keep fields in rows for larger screens */
    }

    .upload-list {
        gap: 12px; /* Adjust gap between upload items */
    }

    .upload-item {
        padding: 8px 12px; /* Adjust padding */
    }

    .upload-icon {
        font-size: 1.3rem; /* Adjust icon size */
    }

    .next-page {
        justify-content: flex-end; /* Keep the alignment of the next button */
        margin-right: 10px;
    }
    .form-container-part input[name="website"].web-margin {
        margin-left: 400px;
    }
    .Webinput-container label{
        margin-left: 400px ;
        display: block;
    }
}

  @media screen and (max-width: 768px) {
    .Status-sidebar {
        display: none;
    }
    .form-container-part {
        width: 100%;
        padding: 15px;
        margin: 0 auto;
        border: none;
    }

    .form-container-part input[type="text"],
    .form-container-part input[type="number"],
    .form-container-part input[type="tel"],
    .form-container-part input[type="email"],
    .form-container-part input[type="date"],
    .form-container-part textarea,
    .form-container-part input[type="file"],
    .form-container-part select {
        width: 300px;
        height: 48px; /* Adjust height for mobile */
        margin-left: 50px;
    }

    .submit-bt {
        width: 20%;
        height: 48px; /* Adjust button size for mobile */
    }

    .two {
        flex-direction: row; /* Stack fields vertically on mobile */
        gap: 10px;
        white-space: nowrap;
    }


    .upload-list {
        gap: 10px;
    }

    .upload-item {
        padding: 8px 10px;
    }

    .upload-icon {
        font-size: 1.2rem; /* Adjust icon size for mobile */
    }
    .form-container-part h3,
    .form-container-part label {
        padding-right: 15px;
        font-size: 14px;
        padding-left:60px ;
    }
    .form-container-part h3{
        font-size: 20px;
        padding-left: 50px;
    }

    .form-container-part input,
    .form-container-part textarea,
    .form-container-part select {
        margin-left: 50px; /* Center the inputs */
    }
    .next-page{
        margin-right: 20px;
    }
    .error {
        padding-left: 50px;
      }  
      .form-container-part input[name="website"].web-margin {
        margin-left: 420px;
    }
    .Webinput-container label{
        margin-left: 420px ;
        display: block;
    }
    .form-container-part input[name="registeredAddress"].address-1 {
        width: 88%;
        height: 60px;
    }
    
    /* CSS for Store Address-2 */
    .form-container-part input[name="registeredAddress1"].address-2 {
        width: 88%;
        height: 60px;

    }
    .Partcheckbox-label {
        font-size: 14px;
      }
    
      .Partcustom-checkbox {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    
      .Partcustom-checkbox:checked::before,
      .add-more-checkbox::after {
        font-size: 12px;
        left: 3px;
      }
      .documentation-section {
        padding-left: 50px;
      }
}

@media screen and (max-width: 480px) {
    .form-container-part {
        padding: 10px;
    }

    .form-container-part input[type="text"],
    .form-container-part input[type="number"],
    .form-container-part input[type="tel"],
    .form-container-part input[type="email"],
    .form-container-part input[type="date"],
    .form-container-part textarea,
    .form-container-part input[type="file"],
    .form-container-part select {
        width: 80%;
        height: 40px; /* Further adjust input height for smaller devices */
        margin: 0 auto;
    }

    .submit-bt {
        width: 30%;
        height: 40px; /* Further adjust button size */
    }

    .Status-sidebar {
        min-width: 180px; /* Reduce sidebar width further for small screens */
        padding: 5px;
    }

    .upload-item {
        padding: 5px 8px;
    }

    .upload-icon {
        font-size: 1rem;
    }
}

.Partcheckbox-group {
    color: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .Partcheckbox-label {
    display:flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .Partcustom-checkbox {
    appearance: none ;
    border: 2px solid transparent;
    background: linear-gradient(to right, #000, #000), 
                linear-gradient(to right, #FF6B00, #FFBE00);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 10px;
    display: inline-block;
    position: relative;
  }
  .Partcustom-checkbox input{
    width: 20px;
    appearance: none;  }
  .Partcustom-checkbox:checked::before {
    content: '✓'; /* Add checkmark */
    color: #FFBE00;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 6px;
    font-size: 20px;
  }
  
  .Partcustom-checkbox:hover {
    border: 1px solid #FFBE00;
  }
  
  .Partcheckbox-label input:checked + .Partcheckbox-label {
    color: #FFBE00;
  }

  /* For the 'Add more' checkbox */
.add-more-checkbox {
    position: relative;
    appearance: none;
    border: 2px solid #fff;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  /* Always show '+' symbol inside the 'Add more' checkbox */
  .add-more-checkbox::before {
    content: '+'; /* Show '+' symbol */
    color: #FFF;
    font-weight: bold;
    position: absolute;
    top: 1px;
    left: 7px;
    font-size: 24px;
  }
  
  /* No checkmark when checked for the 'Add more' checkbox */
  .add-more-checkbox:checked::before {
    content: '+'; /* Retain the '+' symbol */
  }
  
  .add-more-checkbox:checked {
    background-color: #000; /* Keep background same on check */
  }
  
  .add-service-button:focus {
    outline: none; /* Remove the focus outline */
    color: #FF6B00;
  }
  .add-service-container {
    display: flex;
    align-items: center;
    background-color: #000;
    width: 200px; /* Make it take full width */
    border-bottom: 2px solid gray;
  }
  .add-service-input::placeholder {
    color: #999; /* Gray placeholder text */
  }
  
  .add-service-input[type='text'] {
    flex: 1; /* Take up remaining space */
    border: none !important; /* Remove default border */
    background: transparent !important; /* Transparent background */
    color: #FFF; /* White text color */
    font-size: 16px; /* Font size for the input */
    padding: 0 10px; /* Padding for spacing */
    outline: none; /* Remove outline */
    caret-color: #FFBE00; /* Orange caret color */
    appearance: none !important ;
    box-shadow: none !important;
  }
  .add-service-button {
    background-color: #000; /* Black background */
    color: #FFBE00; /* Orange text */
    border: none; /* Remove default borders */
    padding: 0px 16px; /* Padding for the button */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 30px; /* Large font for checkmark */
    border-radius: 50%; /* Circular button */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    width: 52px; /* Circular size */
    margin-left: 10px; /* Spacing between input and button */
  }
  .chevronADD-icon{
    margin-left: 5px;
  }
  
  select option {
    background-color: #000; /* Background color */
    color: #FFF; /* Text color */
    padding: 10px; /* Padding */
    appearance: none;
  }
  