.proceeddetailsarrowright {
  width: 278px;
  padding: 10px 5px 10px 5px;
  top: 238px;
  color: #ffffff;
  gap: 8px;
  border: 3px;
  border-radius: 16px;

  background: linear-gradient(90deg, #ff6b00 42.78%, #ffb300 100%);
  text-align: center;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.popup-content {
  background: #161616;
  padding: 20px;
  border-radius: 16px;
  text-align: center;
  border: 2px solid #FFBE00;
  width: 1026px;
  height: 439px;
}

.popup-content h2 {
  margin-bottom: 10px;
  background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0;
  font-size: 35px;

}

.popup-content button {
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  width: 300px;
  height: 56px;
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
}

.popup-form label {
  margin-bottom: 8px;
  font-size: 16px;
  color: #fff;
  font-family: 'Chakra Petch', sans-serif;
}

.popup-form input {
  width: 300px;
  height: 56px;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 42px;
  background: linear-gradient(to right, #161616, #161616),
      linear-gradient(to right, #FF6B00, #FFBE00);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
}
.popup-form input:focus {
  outline: none; /* Removes the default blue outline */
  box-shadow: 0 0 10px rgba(255, 190, 0, 0.8);
  border: 2px solid #FFBE00;
}
.popup-form select {
  width: 300px;
  height: 56px;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 25px;
  background: linear-gradient(to right, #161616, #161616),
      linear-gradient(to right, #FF6B00, #FFBE00);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #000;
  color: gray;
  font-size: 14px;
  margin-bottom: 10px;
  outline: none;
}
.popup-form select:focus {
  outline: none; /* Removes the default blue outline */
  box-shadow: 0 0 10px rgba(255, 190, 0, 0.8);
  border: 2px solid #FFBE00;
}

.popup-row {
  display: flex;
  gap: 50px;
  justify-content: center;
}
.popup-row input {
width: 300px;
height: 56px;
}

.popup-row-center label {
  margin-bottom: 8px;
  font-size: 16px;
  color: #fff;
  font-family: 'Chakra Petch', sans-serif;
  justify-content: center;
}
.popup-row-center input {
  width: 300px;
  height: 56px;
}
.cencel-close button {
background: none;
color: #FF6B00;
float: right;
width: auto;
height: auto;
padding: 0;
}

.washingpage-container {
  background-color: #000000;
  display: flex;
}

.service-container {
  margin-left: 20px;
  padding: 15px;
  width: 100%;
  
}
.top-head h1 {
  font-size: 40px;
  font-weight: bold;
  background: linear-gradient(400deg, #ff6B00 20%, #ffBE00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; /* For non-WebKit browsers */
  margin: 0;
}

.filter-sidebar {
  width: 250px;
  padding: 20px;
  background-color: #000;
  border-radius: 10px;
  margin-right: 20px;
}
.service-header {
  height: auto;
  
  /* background-color: #a8a4a4; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-items: center;
}

.service-header .date-time {
  color: #ffffff;
  display: flex;
  justify-content: end;
  gap: 6px;
  align-items: center;
}

.service-header .date-time .calender,
.car {
  color: #f0b90b;
  width: 20px;
  height: 20px;
}

.service-container .service-header h1 {
  font-size: 48px;
  width: 50%;

  background: linear-gradient(400deg, #ff6B00 20%, #ffBE00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.service-header .date-time .edit {
  border: 2px solid #f0b90b;
  border-radius: 14px;
  color: #ffffff;
  padding: 3px 10px;
  font-size: 10px;
  align-items: center;
  
  background: linear-gradient(to right, #161616, #161616),
    linear-gradient(to right, #ff6b00, #ffbe00);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #161616;
  
  
}
.service-header .date-time .edit .edit-pencil {
  margin-right: 4px;
  color: #f0b90b;
  transition: background-color 0.3s, color 0.3s;
  background: linear-gradient(to right, #161616, #161616),
    linear-gradient(to right, #ff6b00, #ffbe00);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #161616;
}

h3 {
  color: #fff;
}

.clear-btn,
.apply-btn {
  background-color: transparent;
  border: none;
  color: #f0b90b;
  cursor: pointer;
  margin-bottom: 10px;
  text-align: left;
}

.apply-btn {
  display: inline-block;
  padding: 5px 10px;
  background-color: #f0b90b;
  border-radius: 15px;
  color: black;
  margin-top: 20px;
}

.service-list {
 
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.service-card {
  display: flex;
  border-radius: 10px;
  padding: 20px;
  
  gap: 10px;
  border: 1px solid transparent;
  transition: border-color 0.3s;
  border-radius: 25px;
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #161616;
  box-shadow: 6px 6px 12px rgba(255, 190, 0, 0.25);
  width: 917px;
  height: 204px;
}
 
.showchart{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.service-card.selected {
  border-color: #f0b90b;
}
.service-logo {
  width: 200px;
  height: 200px;
  /* border: 2px solid transparent; */
  /* border: 2px solid #ee1a25; */
  border-radius: 8px;
  background: linear-gradient(to right, #161616, #161616),
    linear-gradient(to right, #ff6b00, #ffbe00);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #161616;
  background-position: left;
  background-size: cover;
}
.service-meta {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: baseline;
  color: #fff;
  font-size: 12px;
  margin-top: 24px;
  padding-left: 2px;
}
.service-meta span {
  font-size: 16px;
  font-weight: 500;
}

.service-meta img {
  width: 14px;
  height: 14px;
}

.left-img {
  object-fit: contain;
  border-radius: 25px;
  width: 192px;
  height: 200px;
}

.service-info {
  /* background-color: #c21e1e; */
  margin-left: 20px;
  gap: 20px;
}

.service-info h4 {
  color: #f0b90b;
  margin-bottom: 5px;
  font-size: 35px;
   margin: 0;
}
.service-info .service-meta .descleft {
  padding: 3px 0;
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  /* background-color: blue; */
  
}
.service-info .service-meta .descleft .descleft-desc{
  height: 145px;
  
  /* background-color: #e11b1b; */
}
.service-info .service-meta .descleft .descleft-desc ul{
  margin-top: 16px;
}

.service-info .service-meta .descleft .desctext{
  line-height: 18px;
  color: rgb(230, 220, 220);
  font-style: italic;
}

.service-ratadddist {
  display: flex;
  width: 100%;
  height: 100%;
  /* background-color: yellow; */
  color: #ffffff;
  justify-content: space-between;
}
.service-info p .dropdowncircle {
  margin-left: 4px;
  border: 1.33px solid #f0b90b;
  border-radius: 50%;
  color: #f0b90b;
  font-size: 10px;
  align-items: center;
}
.service-price {
    padding-top: 8px;
  font-size: 24px;
  /* background-color: #9a7e7e; */
  display: flex;
  justify-content: end;
  font-weight: 600;
}
.service-price-P {
  /* background-color: #c11a1a; */
  font-size: 24px;
  color: #fff;
}

.service-action {
  /* background-color: #f90808; */
  height: 100%;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-direction: column;
}

.service-action p {
  height: 34px;
  /* background-color: #10e434; */
  width: 84px;
}

.select-btn {
  width: 249px;
  height: 52px;
  background-color: transparent;
  color: #ffffff;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;

  border: 3px solid transparent;
  border-radius: 25px;
  transition: background-color 0.3s, color 0.3s;
  background: linear-gradient(to right, #161616, #161616),
    linear-gradient(to right, #ff6b00, #ffbe00);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #161616;
}
.select-btn .selected {
  color: #f7f7f7;
}
.select-btn:hover {
  background: #f0b90b;
  color: black;
}

.select-btn.selected-btn {
  background-color: #f0b90b;
  color: rgb(255, 255, 255);
  font-size: 18px;
}

.service-partnerarrow {
  /* background-color: #ab0c71; */
  padding: 20px;
  justify-content: end;
  align-items: center;
  gap: 5px;
  border-radius: 16px;
  width: 278px;
  text-align: center;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  white-space: nowrap;

}
.proceeddetailsarrowright {
  width: 278px;
  padding: 10px 5px 10px 5px;
  top: 238px;
  color: #ffffff;
  gap: 8px;
  border: 3px;
  border-radius: 16px;

  background: linear-gradient(90deg, #ff6b00 42.78%, #ffb300 100%);
  text-align: center;
}


.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background: #161616;
  padding: 20px;
  border-radius: 16px;
  text-align: center;
  border: 2px solid #FFBE00;
}

.popup-content h2 {
  margin-bottom: 10px;
  background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0;
  font-size: 35px;

}

.popup-content button {
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 200px;
  height: 40px;
  background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 100%);
}

.popup-form label {
  margin-bottom: 8px;
  font-size: 16px;
  color: #fff;
  font-family: 'Chakra Petch', sans-serif;
}

.popup-form input {
  width: 100%;
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 25px;
  background: linear-gradient(to right, #161616, #161616),
      linear-gradient(to right, #FF6B00, #FFBE00);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  margin-bottom: 10px;
}
.popup-form input:focus {
  outline: none; /* Removes the default blue outline */
  box-shadow: 0 0 10px rgba(255, 190, 0, 0.8);
  border: 2px solid #FFBE00;
}
.popup-row {
  display: flex;
  gap: 50px;
}

.popup-row input {
  width: 250px;
  height: 25px;
}

.popup-row-center label {
  margin-bottom: 8px;
  font-size: 16px;
  color: #fff;
  font-family: 'Chakra Petch', sans-serif;
  justify-content: center;
}
.popup-row-center input {
  width: 250px;
  height: 25px;
}
.cencel-close button {
background: none;
color: #FF6B00;
float: right;
width: auto;
height: auto;
padding: 0;
}

.des-list {
width: 323px;
text-align: start;
color: #ffffff;
font-size: 12px;
padding: 0;
margin-left: 12px;
padding-bottom: 4px;
}

.break-list {

}

.gradient-text {
font-weight: 400;
font-size: 32px;
background: linear-gradient(90deg, #FF6B00 0%, #FFBE00 40.7%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text; /* For other browsers */

}

.title-name {
background: linear-gradient(90deg, #FFBE00 0%, #FF6B00 40.7%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text; /* For other browsers */
font-size: 22px;

}


/* media query */

/* General Styles for Large Screens (Desktops) */
@media (min-width: 1024px) {
.washingpage-container {
  flex-direction: row;
}

.filter-sidebar {
  display: block;
}
}

/* Medium Screens (Tablets) */
@media (max-width: 1023px) and (min-width: 768px) {
.service-container .service-header h1 {
  font-size: 36px;
}

.service-card {
  flex-direction: column;
  height: auto;
  width: 100%;
}

.left-img {
  width: 100%;
  height: 150px;
}
}

/* Small Screens (Mobile) */
@media (max-width: 767px) {
.washingpage-container {
  flex-direction: column;
  padding: 10px;
}

.filter-sidebar {
  display: none;
}



.top-head h1 {
  font-size: 16px;
  text-align: center;
}

.service-container .service-header h1 {
  font-size: 24px;
  width: 100%;
}

.service-card {
  width: 100%;
  flex-direction: column;
  gap: 15px;
  height: auto;
}

.service-logo {
  width: 100px;
  height: 100px;
}

.service-info h4 {
  font-size: 24px;
}

.popup-row, .popup-row-center {
  flex-direction: column;
  gap: 10px;
}

.popup-row input, .popup-row-center input {
  width: 100%;
}

.service-action {
  align-items: center;
}
}
.selected-service-card {
background-color: white; /* White background */
padding: 16px; /* Padding around the content */
margin-top: 10px; /* Space between the service card and selected card */
border-radius: 8px; /* Rounded corners */
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */

}

.selected-btn {
background: linear-gradient(270deg, #FFBE00 0%, #FF6B00 25.39%);
color: white; /* White text color */
border: none; /* No border */
width: 128px;
height: 50px;
border-radius: 42px; /* Rounded corners */
font-weight: 600;
font-size: 18px;

}

.service-card {
/* Other styles for your service card */
transition: height 0.3s ease; /* Smooth transition */
}



.service-card.selected {
height: 430px; /* Increase the height for the selected card */

}

.service-card:not(.selected) {
height: 180px; /* Normal height for non-selected cards */
align-items: center;
}

.price-breakup-list {
width: 669px;
height: 248px;
border: 1px solid red;
margin-top: 10px;
}

.row-one {
width: 100%;
height: 36px;
border: 1px solid green;
}
.row-two {
width: 100%;
height: 36px;
border: 1px solid green;
}
.row-three {
width: 100%;
height: 36px;
border: 1px solid green;
}
.row-four {
width: 100%;
height: 36px;
border: 1px solid green;
}
.row-five {
width: 100%;
height: 36px;
border: 1px solid yellow;
}

