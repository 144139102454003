
.footer {
  padding: 56px;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: auto;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  height: 200px;
  border-top:2px solid #FFFFFF ;
}
.footer p{
  white-space: nowrap;
}

.footer-column-contact,
.footer-column-policy,
.footer-column-followus {
  padding: 0 40px; 
  box-sizing: border-box;
}
.footer-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  background: linear-gradient(  #FFBE00 20% ,#FF6B00 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Exotc350 DmBd BT', sans-serif;}

.contact-address {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1rem;
}
.contact-address img {
  width: 13.33px;
}
#social-icon {
  color: #fff;
}

.address-lines {
  margin-top: 5px;
}

.social-iconblock a {
  color: #fff;
}
.social-iconblock{
margin-top: 10px;
margin-left: 20px;
}
.social-iconblock img {
  width: 32px;
}
.social-iconblock a:hover {
  color: #FFBE00;
}
.address-lines span {
  display: block; 
  margin-left: 20px;
}
.footer-policy ul li {
  color: #fff; /* Set the text color to white */
  margin-bottom: 5px;
}

.footer-policy ul li a {
  color: #fff; /* Ensure that the link text is also white */
  text-decoration: none; /* Optional: Remove underline from links */
}
.footer-policy a {
  color: #fff; /* Ensure that the link text is also white */
  text-decoration: none; /* Optional: Remove underline from links */
  white-space: nowrap;
}

.footer-policy ul li a:hover {
  color: #f0f0f0; /* Optional: Change color on hover */
}
@media (max-width: 1024px) {
  .footer {
    width: auto; 
    height: auto;
  }
}
@media (max-width: 480px) {
  .footer {
    padding: 50px;
    background-color: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}