.filter-container {
  background-color: #000000;
  padding: 20px;
  color: #fff; /* White text color */
  font-family: 'Chakra Petch', sans-serif; 
  border-right: 2px solid  #FFFFFF;
  width: 350px;
  padding-left: 34px;
  padding-right: 34px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.clear-apply {
  display: flex;
  gap: 10px;
  justify-content: space-between; /* Space out elements */
  align-items: center;
}

.clear-all {
  cursor: pointer;
  color: #FF6B00;
  padding-top: 6px ;
}

/* .apply-btn {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 25px;
  font-size: 15px;
  gap: 5px;
  font-weight: normal;
  padding-left: 15px;
  padding: 9px 20px;
  transition: background 0.3s;
  border: 2px solid transparent;
  border-radius: 25px;
  background: linear-gradient(to right, #000, #000), 
              linear-gradient(to right,#FF6B00, #FFBE00 ); 
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #000;
  color: #FFBE00;
} */

.filter-section {
  margin-top: 20px;
}
.filter-section h3 {
  color: #fff;
  font-size: 18px;
}
.filter-category {
  margin-bottom: 15px;
}

.dropdown-header {
  font-family: "Chakra Petch", sans-serif;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-group,
.slider-group {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.range-labels {
  display: flex;
  justify-content: space-between;
}

.slider-group input[type="range"] {
  width: 100%;
  margin: 10px 0;
  color: #FF6B00;
}

label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color:#FFBE00;
}

label input[type="checkbox"] {
  margin-right: 10px;
}


.custom-checkbox {
  border: 1.24px solid transparent;
  background: linear-gradient(to right, #000, #000), 
              linear-gradient(to right, #FF6B00, #FFBE00); 
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-color: #000;
  color: transparent;
  border-radius: 4px;
  font-size: 18px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #FFBE00;
  margin-bottom: 10px; /* Add spacing between checkboxes */
}
.checkbox-label .icon {
  color: #FFBE00;}
.checkbox-label .custom-checkbox {
  margin-right: 10px; /* Gap between checkbox and label */
}


/* additional css for range slider */

.slider-group input[type="range"] {
  -webkit-appearance: none; /* Remove default styling */
  width: 100%;
  height: 6px; /* Height of the track */
  background: #bd5c17; /* Default track color */
  border-radius: 5px; /* Round edges */
  outline: none; /* Remove outline */
  margin: 20px 0; /* Spacing */
}

/* WebKit Browsers (Chrome, Safari) Thumb */
.slider-group input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default appearance */
  appearance: none;
  width: 25px;
  height: 25px; /* Adjust size to fit image properly */
  background-image: url('/src/image/carfilter.png'); /* Ensure the path is correct */
  background-size: contain; 
  background-repeat: no-repeat;
  background-position: center;
  border: none; /* Remove border if any */
  cursor: pointer;
}

/* Firefox Thumb */
.slider-group input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background-image: url('/src/image/carfilter.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
